<template>
  <div class="account-pages my-5 pt-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6 col-xl-5">
          <div class="card overflow-hidden">
            <div class="bg-primary">
              <div class="text-primary text-center p-4">
                <h5 class="text-white font-size-20 p-2">Forgot Password</h5>
                <a href="/" class="logo logo-admin">
                  <img src="@/assets/images/logo.png" height="50" alt="logo" />
                </a>
              </div>
            </div>
            <div class="card-body p-4">
              <div class="p-3">
                <b-alert v-model="isResetError" class="mt-3" variant="danger" dismissible>{{error}}</b-alert>
                     <b-alert v-model="isSuccess" class="mt-3" variant="success" dismissible>Silahkan Periksa Email anda 1x24 Jam</b-alert>

                <b-form @submit.prevent="tryToReset" class="form-horizontal mt-4">
                  <b-form-group id="email-group" label="Email" label-for="email">
                    <b-form-input id="email"   v-model="email" 
                    required
                    type="email" placeholder="Enter email"></b-form-input>
                  </b-form-group> 

                  <div class="form-group row mb-0">
                    <div class="col-12 text-right">
                      <b-button type="submit" variant="primary" class="w-md">Reset</b-button>
                    </div>
                  </div>
                </b-form>
              </div>
            </div>
            <!-- end card-body -->
          </div>
          <!-- end card -->
          <div class="mt-5 text-center">
            <p>
              Remember It ?
              <router-link tag="a" to="/" class="font-weight-medium text-primary">Sign In here</router-link>
            </p> 
          </div>
        </div>
        <!-- end col -->
      </div>
      <!-- end row -->
    </div>
  </div>
</template>
<script> 
import appConfig from "@/app.config"; 
/**
 * Forgot password component
 */
export default {
  page: {
    title: "Forgot Password",
    meta: [{ name: "description", content: appConfig.description }]
  },
  data() {
    return {
      email: "",
      error: null,
      tryingToReset: false,
      isResetError: false,
      isSuccess : false,
    };
  },
  methods: { 
    tryToReset() {
       let param = new FormData(); 
      param.append('email', this.email)  
      this.$axios.post('/auth/forgot-password/staff', param)
      .then( res => {
        if(res.status == 200){
          this.isSuccess = true
        }
      }).catch(e => {
         if (e.response) {  
              this.isResetError = true
              this.error = e.response.data.message
         }
       
         
      })
       
    }
  }
};
</script>
<style lang="scss" module></style>
